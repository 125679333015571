import * as CONST from '../../ActionTypes';
import * as util from '../index';

/**
 * 見積依頼履歴詳細取得
 */
export const getDetailDeliveryRequest = (accessToken, id) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/delivery_requests/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      dispatch(util.actionType(CONST.GET_DETAIL_DELIVERY_REQUEST_DRIVER_FAILURE, null));
      return null;
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.actionType(CONST.GET_DETAIL_DELIVERY_REQUEST_DRIVER_SUCCESS, payload));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_DETAIL_DELIVERY_REQUEST_DRIVER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * 見積依頼履歴詳細取得
 */
// eslint-disable-next-line max-len
export const getDetailDeliveryRequestByCompanyMember = (accessToken, id, memberId) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/company_member/${memberId}/delivery_requests/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      dispatch(util.actionType(CONST.GET_DETAIL_DELIVERY_REQUEST_DRIVER_FAILURE, null));
      return null;
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.actionType(CONST.GET_DETAIL_DELIVERY_REQUEST_DRIVER_SUCCESS, payload));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_DETAIL_DELIVERY_REQUEST_DRIVER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * 見積依頼一覧取得
 */
export const getListDeliveryRequest = (accessToken, param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/delivery_requests?limit=${param.limit}&offset=${param.offset}&sort=${param.sort}&status=${param.status}&role=driver`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.actionType(CONST.GET_LIST_DELIVERY_REQUEST_DRIVER_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_LIST_DELIVERY_REQUEST_DRIVER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
//Get list suggestion
export const getListSuggestion = (accessToken, param) => (dispatch) => {
  dispatch(util.loading());
  const textTmp1 = param.isApproved === 'progress' ? '&is_progress=true' : `&is_approved=${param.isApproved != null ? param.isApproved : ''}`;
  const textTmp = param.isApproved === 'determined' ? '&is_determined=true' : textTmp1;
  // eslint-disable-next-line no-nested-ternary
  const text = param.isApproved === 'reject' ? '&is_reject=true' : param.isApproved === 'canceled' ? '&is_canceled=true' : textTmp;
  const sort = param.sort ? `&sort=${param.sort}` : '';
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/delivery_requests/suggestions?limit=${param.limit}&offset=${param.offset}${sort}${text}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.actionType(CONST.GET_LIST_SUGGESTION_DRIVER_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_LIST_SUGGESTION_DRIVER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

//Get list suggestion
export const getListAllSuggestion = (accessToken, param) => (dispatch) => {
  dispatch(util.loading());
  const textTmp1 = param.isApproved === 'progress' ? '&is_progress=true' : `&is_approved=${param.isApproved != null ? param.isApproved : ''}`;
  const textTmp = param.isApproved === 'determined' ? '&is_determined=true' : textTmp1;
  // eslint-disable-next-line no-nested-ternary
  const text = param.isApproved === 'reject' ? '&is_reject=true' : param.isApproved === 'canceled' ? '&is_canceled=true' : textTmp;
  const sort = param.sort ? `&sort=${param.sort}` : '';
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/delivery_requests/all_suggestions?limit=${param.limit}&offset=${param.offset}${sort}${text}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.actionType(CONST.GET_LIST_SUGGESTION_DRIVER_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_LIST_SUGGESTION_DRIVER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
export const postSuggestion = (accessToken: string, id: string, param: object) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/delivery_requests/${id}/suggestion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.POST_SUGGESTION_DRIVER_SUCCESS, null));
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.requestFailure(CONST.POST_SUGGESTION_DRIVER_FAILURE, null));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.POST_SUGGESTION_DRIVER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
export const clearPostSuggestionResult = () => (dispatch) => {
  dispatch(util.requestFailure(CONST.CLEAR_POST_SUGGESTION_RESULT_DRIVER, null));
};
export const editSuggestion = (accessToken: string, id: string, param: object) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/delivery_requests/suggestions/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200 || response.status === 204) {
        dispatch(util.requestSuccess(CONST.EDIT_SUGGESTION_DRIVER_SUCCESS, null));
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.requestFailure(CONST.EDIT_SUGGESTION_DRIVER_FAILURE, null));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.EDIT_SUGGESTION_DRIVER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
export const clearEditSuggestionResult = () => (dispatch) => {
  dispatch(util.requestFailure(CONST.CLEAR_EDIT_SUGGESTION_RESULT_DRIVER, null));
};
export const approveSuggestion = (accessToken, param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/delivery_requests/${param.deliveryRequestID}/suggestions/${param.suggestionID}/approve`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200 || response.status === 204) {
        dispatch(util.requestSuccess(CONST.APPROVE_SUGGESTION_BY_CUSTOMER_SUCCESS, null));
        dispatch(util.unLoading());
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.requestFailure(CONST.APPROVE_SUGGESTION_BY_CUSTOMER_FAILURE, null));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.APPROVE_SUGGESTION_BY_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
export const rejectSuggestion = (accessToken, param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/delivery_requests/${param.deliveryRequestID}/suggestions/${param.suggestionID}/approve`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200 || response.status === 204) {
        dispatch(util.requestSuccess(CONST.REJECT_BY_CUSTOMER_SUCCESS, null));
        dispatch(util.unLoading());
        return true;
      }
      return true;
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.requestFailure(CONST.APPROVE_SUGGESTION_BY_CUSTOMER_FAILURE, null));
      }
      dispatch(util.unLoading());
      return false;
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.APPROVE_SUGGESTION_BY_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
      return false;
    });
};
export const clearApproveSuggestion = () => (dispatch) => {
  dispatch(util.requestFailure(CONST.CLEAR_APPROVE_SUGGESTION_BY_CUSTOMER, null));
};

export const UpdateReadAtSuggestion = (accessToken, suggestionID) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/messages/read_at/${suggestionID}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.READ_AT_BY_CUSTOMER_SUCCESS, null));
        dispatch(util.unLoading());
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.requestFailure(CONST.READ_AT_BY_CUSTOMER_FAILURE, null));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.READ_AT_BY_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

export const WriteDeliveryRequestPDF = (
  accessToken,
  deriveryRequestID,
) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/delivery_requests/${deriveryRequestID}/write_pdf`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      if (payload.pdf) {
        const linkSource = `data:pdf;base64,${payload.pdf}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = '配送依頼.pdf';
        downloadLink.click();
        // NotificationManager.success('請求書のダウンロードが完了しました');
        // if (props) {
        //   // props.history.replace('/invoice_list');
        // }
      } else {
        // NotificationManager.error(payload.detail || 'エラーが発生しました。時間をおいてもう一度お試しください');
      }
      dispatch(util.unLoading());
    })

    .catch((error) => {
      dispatch(util.requestFailure(CONST.EDIT_SUGGESTION_DRIVER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
